package de.geomobile.frontend.features.softwareManagement.assignment

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.button.*
import com.ccfraser.muirwik.components.card.mCard
import com.ccfraser.muirwik.components.card.mCardActionArea
import com.ccfraser.muirwik.components.card.mCardActions
import com.ccfraser.muirwik.components.card.mCardContent
import com.ccfraser.muirwik.components.dialog.mDialog
import com.ccfraser.muirwik.components.dialog.mDialogActions
import com.ccfraser.muirwik.components.dialog.mDialogContent
import com.ccfraser.muirwik.components.dialog.mDialogTitle
import com.ccfraser.muirwik.components.form.MFormControlMargin
import com.ccfraser.muirwik.components.form.MFormControlVariant
import com.ccfraser.muirwik.components.input.MInputProps
import com.ccfraser.muirwik.components.lab.alert.MAlertSeverity
import com.ccfraser.muirwik.components.lab.alert.MAlertVariant
import com.ccfraser.muirwik.components.lab.alert.mAlert
import com.ccfraser.muirwik.components.list.mListSubheader
import com.ccfraser.muirwik.components.menu.mMenu
import com.ccfraser.muirwik.components.menu.mMenuItem
import com.ccfraser.muirwik.components.styles.Breakpoint
import com.ccfraser.muirwik.components.transitions.mCollapse
import de.geomobile.common.filter.*
import de.geomobile.common.portalmodels.CompanySmall
import de.geomobile.common.portalmodels.DeviceIdentifier
import de.geomobile.common.portalmodels.Product
import de.geomobile.common.softwaremgmt.ChangeSoftwareAssignmentFilterDTO
import de.geomobile.common.softwaremgmt.SoftwareAssignment
import de.geomobile.common.softwaremgmt.SoftwareAssignmentFilter
import de.geomobile.common.softwaremgmt.SoftwareBundle
import de.geomobile.common.time.LocalDateTime
import de.geomobile.frontend.GlobalStyles
import de.geomobile.frontend.features.device.list.DeviceListItem
import de.geomobile.frontend.features.device.list.deviceList
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.utils.*
import kotlinext.js.js
import kotlinext.js.jsObject
import kotlinx.browser.localStorage
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import kotlinx.css.*
import kotlinx.html.InputType
import kotlinx.serialization.builtins.ListSerializer
import kotlinx.serialization.builtins.serializer
import kotlinx.serialization.json.Json
import org.w3c.dom.Node
import org.w3c.dom.get
import org.w3c.dom.set
import react.*
import styled.css
import styled.styledDiv

fun RBuilder.assignmentDetail(
    newParentId: Int?,
    newPosition: Int?,
    product: Product,
    softwareAssignmentFilter: SoftwareAssignmentFilter?,
    branch: List<SoftwareAssignmentFilter?>,
    deviceFilter: Map<String, Filter<DeviceListItem, *>>,
    onSaved: (filterId: Int?) -> Unit,
    onDeviceClick: (id: DeviceIdentifier) -> Unit,
) = child(AssignmentDetail::class) {
    attrs.newParentId = newParentId
    attrs.newPosition = newPosition
    attrs.softwareAssignmentFilter = softwareAssignmentFilter
    attrs.branch = branch
    attrs.deviceFilter = deviceFilter
    attrs.onSaved = onSaved
    attrs.onDeviceClick = onDeviceClick
    attrs.product = product

    attrs.key = softwareAssignmentFilter?.id.toString()
}

class AssignmentDetail : CComponent<AssignmentDetail.Props, AssignmentDetail.State>() {

    private var saveJob: Job = Job()

    interface Props : RProps {
        var newParentId: Int?
        var newPosition: Int?
        var product: Product
        var softwareAssignmentFilter: SoftwareAssignmentFilter?
        var branch: List<SoftwareAssignmentFilter?>
        var deviceFilter: Map<String, Filter<DeviceListItem, *>>
        var companies: List<CompanySmall>
        var onSaved: (filterId: Int?) -> Unit
        var onDeviceClick: (id: DeviceIdentifier) -> Unit
    }

    class State(
        var validFilter: Boolean = true,

        var newBundle: SoftwareBundle? = null,

        var filterChanged: Boolean = false,

        var overwritePolling: Boolean? = null,
        var newPollingInterval: Int? = null,

        var newName: String? = null,
        var newFilter: String? = null,

        var showHistory: Boolean = false,

        var showDeviceList: Boolean = localStorage["softwareAssignmentShowDeviceList"]?.toBoolean() ?: true,
        var deviceCount: Int = 0,

        var menuAnchor: Node? = null,
    ) : RState

    init {
        state = State()
    }

    override fun RBuilder.render() {
        val new = props.softwareAssignmentFilter == null

        val originalBundle = props.softwareAssignmentFilter?.assignment?.bundle
        val bundle = state.newBundle ?: originalBundle

        val overwritePolling =
            state.overwritePolling ?: (props.softwareAssignmentFilter?.assignment?.pollingIntervalInSec != null)

        val pollingInterval = if (overwritePolling) state.newPollingInterval
            ?: props.softwareAssignmentFilter?.assignment?.pollingIntervalInSec ?: 60
        else null

        if (props.softwareAssignmentFilter != null) {
            mDialog(
                open = state.showHistory,
                fullWidth = true,
                maxWidth = Breakpoint.lg,
                onClose = { _, _ -> setState { showHistory = false } }
            ) {
                css {
                    children {
                        lastChild {
                            children {
                                lastChild {
                                    height = 90.pct
                                }
                            }
                        }
                    }
                }
                mDialogTitle(
                    text = "History \"${props.softwareAssignmentFilter!!.name}\""
                )
                mDialogContent {
                    assignmentHistory(
                        id = props.softwareAssignmentFilter!!.id,
                        deviceFilter = props.deviceFilter,
                        product = props.product
                    )
                }
                mDialogActions {
                    mButton(
                        "Close",
                        onClick = { setState { showHistory = false } }
                    )
                }
            }
        }

        mGridContainer2(direction = MGridDirection.column) {
            mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                mCard {
                    css(GlobalStyles.card)

                    mMenu(
                        open = state.menuAnchor != null,
                        anchorElement = state.menuAnchor,
                        onClose = { _, _ -> setState { menuAnchor = null } }
                    ) {
                        mMenuItem(
                            primaryText = "Reassign",
                            onClick = {
                                setState { menuAnchor = null }
                                saveSoftwareAssignment(assign = true, cancel = false)
                            }
                        )

                        if (props.softwareAssignmentFilter?.assignment?.canceled != true)
                            mMenuItem(
                                primaryText = "Cancel",
                                onClick = {
                                    setState { menuAnchor = null }
                                    saveSoftwareAssignment(assign = false, cancel = true)
                                }
                            )
                    }

                    mCardContent {
                        css(GlobalStyles.cardBoxContent)
                        mListSubheader(heading = "Assignment")
                        mDivider { }
                        styledDiv {
                            css { padding(2.spacingUnits) }

                            mTextField(
                                label = "Name",
                                fullWidth = true,
                                margin = MFormControlMargin.dense,
                                value = state.newName ?: props.softwareAssignmentFilter?.name ?: "Neuer Filter",
                                variant = MFormControlVariant.outlined,
                                onChange = {
                                    val value = it.targetInputValue
                                    setState { newName = value.takeIf { it != props.softwareAssignmentFilter?.name } }
                                }
                            ) {
                                attrs.inputProps = if (!new) {
                                    jsObject<MInputProps> {
                                        startAdornment = buildElement {
                                            mIconButtonNoTranslate(
                                                size = MButtonSize.small,
                                                iconName = "history",
                                                onClick = { setState { showHistory = true } }
                                            ) {
                                                css { fontSize = LinearDimension.inherit }
                                                attrs.edge = MIconEdge.start
                                            }
                                        }
                                        endAdornment = buildElement {
                                            mIconButtonNoTranslate(
                                                size = MButtonSize.small,
                                                iconName = "more_vert",
                                                onClick = {
                                                    val anchor = it.currentTarget.asDynamic()
                                                    setState {
                                                        menuAnchor = anchor
                                                    }
                                                }
                                            ) {
                                                css { fontSize = LinearDimension.inherit }
                                            }
                                        }
                                    }
                                } else jsObject {}
                            }

                            styledDiv {
                                css { marginTop = 1.spacingUnits }

                                mTextField(
                                    margin = MFormControlMargin.dense,
                                    label = "Filter",
                                    value = state.newFilter
                                        ?: props.softwareAssignmentFilter?.filterRules?.queryString(props.deviceFilter)
                                        ?: "",
                                    variant = MFormControlVariant.outlined,
                                    error = when {
                                        state.filterChanged -> !state.validFilter
                                        else -> props.softwareAssignmentFilter?.filterValid == false
                                    },
                                    fullWidth = true,
                                    onChange = {
                                        val value = it.targetInputValue
                                        val parsed = try {
                                            value.parseFilterRules(props.deviceFilter)
                                        } catch (e: Throwable) {
                                            null
                                        }
                                        setState {
                                            newFilter = value
                                            filterChanged =
                                                props.softwareAssignmentFilter == null || parsed != props.softwareAssignmentFilter?.filterRules
                                            validFilter = parsed != null
                                        }
                                    }
                                )
                            }
                        }
                    }

                    if (state.newName != null || state.filterChanged || props.newParentId != null || props.newPosition != null || state.newBundle != null || state.overwritePolling != null || (state.newPollingInterval != null && overwritePolling)) {
                        val onlyAssign =
                            state.filterChanged || props.newParentId != null || props.newPosition != null || state.newBundle != null

                        mDivider { }

                        mCardActions {
                            css { padding(2.spacingUnits) }
                            mGridContainer2(
                                direction = MGridDirection.row
                            ) {
                                mGridItem2(
                                    MGridBreakpoints2(MGridSize2.Auto)
                                        .down(Breakpoint.xs, MGridSize2.Cells12)
                                ) {
                                    if (!onlyAssign)
                                        mButton(
                                            caption = "Übernehmen",
                                            variant = MButtonVariant.contained,
                                            color = MColor.secondary,
                                            disabled = (if (state.filterChanged) !state.validFilter else props.softwareAssignmentFilter?.filterValid == false) || state.newName?.isBlank() == true || bundle == null,
                                            onClick = { saveSoftwareAssignment(assign = false, cancel = false) }
                                        ) {
                                            attrs.disableElevation = true
                                            attrs.fullWidth = true
                                        }

                                    mButton(
                                        if (!onlyAssign) "Save & Reassign" else "Save & Assign",
                                        variant = if (!onlyAssign) MButtonVariant.text else MButtonVariant.contained,
                                        color = MColor.secondary,
                                        disabled = (if (state.filterChanged) !state.validFilter else props.softwareAssignmentFilter?.filterValid == false) || state.newName?.isBlank() == true || bundle == null,
                                        onClick = { saveSoftwareAssignment(true, cancel = false) }
                                    ) {
                                        attrs.disableElevation = true
                                        attrs.fullWidth = true
                                    }
                                }
                                mGridItem2(
                                    MGridBreakpoints2(MGridSize2.Auto)
                                        .down(Breakpoint.xs, MGridSize2.Cells12)
                                ) {
                                    mButton(
                                        caption = "Abbrechen",
                                        onClick = {
                                            clearChanges()
                                            props.onSaved(props.softwareAssignmentFilter?.id)
                                        },
                                        color = MColor.default,
                                        variant = MButtonVariant.contained,
                                    ) {
                                        attrs.disableElevation = true
                                        attrs.fullWidth = true
                                    }
                                }
                            }
                        }
                    }
                }
            }
            mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                mCard {
                    css(GlobalStyles.card)

                    mCardContent {
                        css(GlobalStyles.cardBoxContent)
                        mListSubheader(heading = "Polling-Interval")
                        mDivider { }
                        styledDiv {
                            css { padding(2.spacingUnits) }

                            mTextField(
                                label = "Interval",
                                value = pollingInterval?.toString() ?: "INHERIT",
                                variant = MFormControlVariant.outlined,
                                type = if (pollingInterval != null) InputType.number else InputType.text,
                                disabled = !overwritePolling,
                                fullWidth = true,
                                margin = MFormControlMargin.dense,
                                onChange = {
                                    val value = it.targetInputValue.toInt()
                                    setState {
                                        newPollingInterval =
                                            value.takeIf { it != props.softwareAssignmentFilter?.assignment?.pollingIntervalInSec }
                                    }
                                }
                            ) {
                                attrs.inputProps = jsObject<MInputProps> {
                                    inputProps = js { min = 10 }
                                    endAdornment = buildElement {
                                        mSwitch(
                                            overwritePolling,
                                            size = MIconButtonSize.small,
                                            onChange = { _, checked ->
                                                setState {
                                                    this.overwritePolling =
                                                        checked.takeIf { it != (props.softwareAssignmentFilter?.assignment?.pollingIntervalInSec != null) }
                                                }
                                            })
                                    }!!
                                }
                            }

                            if (props.softwareAssignmentFilter != null) {
                                styledDiv {
                                    css { marginTop = 1.spacingUnits }

                                    mAlert(
                                        variant = MAlertVariant.outlined,
                                        severity = MAlertSeverity.info,
                                        message = "Zugewiesen am ${props.softwareAssignmentFilter!!.assignment.assignedAt.toText()} " + "von ${props.softwareAssignmentFilter!!.assignment.assignedBy}",
                                    )

                                    if (props.softwareAssignmentFilter?.assignment?.canceled == true)
                                        mAlert(
                                            variant = MAlertVariant.outlined,
                                            severity = MAlertSeverity.error,
                                            message = "CANCELED"
                                        ) { css { marginTop = 1.spacingUnits } }
                                }
                            }
                        }
                    }
                }
            }
            mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                mCard {
                    css(GlobalStyles.card)
                    mCardContent {
                        css(GlobalStyles.cardBoxContent)
                        mListSubheader(heading = "Software")
                        mDivider { }
                        bundleSelect(
                            bundle = bundle,
                            product = props.product,
                            onBundleChanged = { newBundle ->
                                setState { this.newBundle = newBundle?.takeIf { it != originalBundle } }
                            }
                        )
                    }
                }
            }
            mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                filteredDevices()
            }
        }
    }

    private fun RBuilder.filteredDevices() {
        val products =
            if (props.product == Product.CONNECT) listOf(Product.CONNECT, Product.CONNECT_PLUS, Product.CORE) else listOf(props.product)
        val productRule = FilterRule(
            filterId = "product", Filter.Rule(
                ref = Json.encodeToJsonElement(
                    ListSerializer(String.serializer()), products.map { it.readableName }.toList()
                ),
                operatorId = "IN"
            )
        )

        mCard {
            css(GlobalStyles.card)

            val filter = if (state.validFilter && state.newFilter != null) {
                FilterRules(state.newFilter!!.parseFilterRules(props.deviceFilter).rules + productRule)
            } else props.softwareAssignmentFilter?.let { FilterRules(it.filterRules.rules + productRule) }
                ?: FilterRules(listOf(productRule))

            val matcher = FilterMatcher(
                filterRules = FilterRules(
                    rules = props.branch.dropLast(1).flatMap { it!!.filterRules.rules }.plus(filter.rules)
                ),
                filters = props.deviceFilter
            )

            mCardContent {
                css(GlobalStyles.cardBoxContent)

                mCardActionArea(
                    onClick = {
                        localStorage["softwareAssignmentShowDeviceList"] = (!state.showDeviceList).toString()
                        setState { this.showDeviceList = !this.showDeviceList }
                    }
                ) {
                    attrs.component = "div"
                    mCardHeaderExtended(
                        title = "${state.deviceCount} Geräte gefunden",
                        action = mIconButtonNoTranslate(
                            if (state.showDeviceList) "expand_less" else "expand_more",
                            onClick = {
                                localStorage["softwareAssignmentShowDeviceList"] = (!state.showDeviceList).toString()
                                setState { this.showDeviceList = !this.showDeviceList }
                            },
                            addAsChild = false
                        )
                    )
                }
                mCollapse(state.showDeviceList) {
                    deviceList(
                        persistenceId = if (props.product == Product.INTERACT) "InteractSoftwareAssignment" else "SoftwareAssignment",
                        onDeviceClick = { props.onDeviceClick(it) },
                        filter = matcher,
                        onFilteredDevicesChanged = {
                            if (state.deviceCount != it.count()) setState { deviceCount = it.count() }
                        }
                    )
                }
            }
        }
    }

    private fun clearChanges() {
        setState {
            newName = null
            newFilter = null
            filterChanged = false
            newBundle = null
            newPollingInterval = null
            overwritePolling = null
        }
    }

    private fun saveSoftwareAssignment(assign: Boolean, cancel: Boolean) {
        val overwritePolling =
            state.overwritePolling ?: (props.softwareAssignmentFilter?.assignment?.pollingIntervalInSec != null)

        val assignmentFilter = SoftwareAssignmentFilter(
            id = props.softwareAssignmentFilter?.id ?: -1,
            name = state.newName ?: props.softwareAssignmentFilter?.name ?: "Neuer Filter",
            filterValid = true,
            filterRules = state.newFilter?.parseFilterRules(props.deviceFilter)
                ?: props.softwareAssignmentFilter?.filterRules ?: FilterRules(emptyList()),
            assignment = SoftwareAssignment(
                bundle = state.newBundle ?: props.softwareAssignmentFilter!!.assignment.bundle,
                pollingIntervalInSec = if (overwritePolling) state.newPollingInterval
                    ?: props.softwareAssignmentFilter?.assignment?.pollingIntervalInSec ?: 60
                else null,
                canceled = cancel,
                createdAt = props.softwareAssignmentFilter?.assignment?.createdAt ?: LocalDateTime.now(),
                createdBy = props.softwareAssignmentFilter?.assignment?.createdBy ?: "",
                assignedAt = props.softwareAssignmentFilter?.assignment?.assignedAt ?: LocalDateTime.now(),
                assignedBy = props.softwareAssignmentFilter?.assignment?.assignedBy ?: ""
            )
        )

        saveJob.cancel()
        saveJob = launch {
            val filterId = withContext(Dispatchers.Default) {
                portalRestApi.put(
                    path = "/software/assignment/${
                        when {
                            assign -> "assign"
                            else -> "save"
                        }
                    }",
                    body = Json.encodeToString(
                        ChangeSoftwareAssignmentFilterDTO.serializer(), ChangeSoftwareAssignmentFilterDTO(
                            assignmentFilter = assignmentFilter,
                            parentId = props.newParentId,
                            position = props.newPosition,
                            product = props.product
                        )
                    ),
                    serializer = Int.serializer()
                )
            }

            clearChanges()
            props.onSaved(filterId)
        }
    }
}