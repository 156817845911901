package de.geomobile.common.portalmodels

import de.geomobile.common.stage.Stage
import de.geomobile.common.time.LocalDate
import de.geomobile.common.time.LocalDateTime
import kotlinx.serialization.Serializable
import portalmodels.FmsProfileDTO
import portalmodels.IbisProfileDTO
import portalmodels.WiringProfileDTO

@Serializable
enum class Product(val readableName: String) {
    ALL("all"),
    CORE("ivantoCore"),
    CONNECT("ivantoConnect"),
    INTERACT("ivantoInteract"),
    CONNECT_PLUS("ivantoConnectPlus");

    companion object {
        val connectProducts = listOf(CONNECT, CONNECT_PLUS)

        fun fromName(readableName: String, ignoreCase: Boolean = true): Product =
            values().single { it.readableName.equals(readableName, ignoreCase) }
    }
}

@Serializable
data class DeviceDTO(
    val id: Int,
    val product: Product,
    val productVariant: String? = null,
    val serialNumber: Int? = null,
    val cpuId: String? = null,
    val hardwareId: Int?,
    val vehicleId: String,
    val vehicleIdLock: Boolean,
    val description: String? = null,
    val company: CompanySmall,
    val stage: Stage,
    val createdAt: LocalDateTime,
    val updatedAt: LocalDateTime,
    val installDate: LocalDate,
    val materialNumber: Int,
    val revision: String,
    val vehicleType: VehicleType,
    val internalDescription: String? = null,
    val vehicleProfile: VehicleProfileSmallDTO? = null,
    val sharedWith: List<CompanySmall>,
    val fmsProfile: FmsProfileDTO? = null,
    val ibisProfile: IbisProfileDTO? = null,
    val wiringProfile: WiringProfileDTO? = null,
//    val ibisProfileVP: IbisProfileDTO? = null,
//    val ibisProfileCP: IbisProfileDTO? = null
)

@Serializable
data class DeviceSmallDTO(
    val id: Int,
    val product: Product,
    val serialNumber: Int? = null,
    val cpuId: String? = null,
    val hardwareId: Int?,
    val vehicleId: String,
    val description: String? = null,
    val company: CompanySmall,
    val stage: Stage,
    val createdAt: LocalDateTime,
    val updatedAt: LocalDateTime,
    val vehicleType: VehicleType,
)