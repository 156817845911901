package portalmodels

import de.geomobile.common.portalmodels.CompanySmall
import de.geomobile.common.portalmodels.R2PWifiCalibrationDTO
import de.geomobile.common.time.LocalDateTime
import kotlinx.serialization.Serializable

enum class CompanyProfileSIMSource(val readableName: String) {
    NONE("Keine"),
    LOCAL("Upload"),
    REMOTE("SFTP");

    companion object {
        private val mapping = values().associateBy(CompanyProfileSIMSource::readableName)
        fun fromReadable(readableName: String) = mapping[readableName]
    }
}

@Serializable
data class CompanyProfileDTO(
    var company: CompanySmall,
    var simSettings: String = "",
    var simDeployedAt: LocalDateTime? = null,
    var simBackupFTPVersion: Int = 0,
    var simBackupFTPHost: String? = "",
    var simBackupFTPPort: String? = "",
    var simBackupFTPUser: String? = "",
    var simBackupFTPPassword: String? = "",
    var simBackupFTPPath: String? = "",
    var simSource: CompanyProfileSIMSource = CompanyProfileSIMSource.NONE,
    var remoteSIMLocation: String = "",
    var remoteSIMInterval: Int = 3600,
    var vehicleProfile: String? = null,
    var fakeGpsEnabled: Boolean = false,
    var sftpProfile: SFTPProfileDTO?,
    var r2pWifiCalibration: R2PWifiCalibrationDTO?,
    var vehicleStatusHistoryEnabled: Boolean? = false,
    var ttsDoorSignalId: Int? = null,
    var ttsPhraseLength: Int? = null,
    //var elaHistory: String? = ""
)

// TODO: Clean up
//@Serializable
//data class CompanyProfileSmallDTO(
//        val company: CompanySmall
//)
//
//val CompanyProfileDTO.small get() = CompanyProfileSmallDTO(company = company)